import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, map, tap } from 'rxjs';
import { UrlEnum } from '../../enum/url.enum';
import { ChangePasswordModel } from '../../model/auth/change-password.model';
import { LoginBodyModel } from '../../model/auth/login.model';
import { OtpResendModel } from '../../model/auth/otp-resend.model';
import { OtpValidationModel } from '../../model/auth/otp-validation.model';
import {
  CorporateSignupBodyModel,
  SignupBodyModel,
} from '../../model/auth/signup.model';
import { HttpRequestService } from '../http-request/http-request.service';
import { LocalStorageService } from '../local-storage/local-storage.service';
import { UtilitiesService } from '../utilities/utilities.service';
import { ForgetPasswordModel } from '../../model/auth/forget-password.model';
import { ResetPasswordModel } from '../../model/auth/reset-password.model';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public shouldShowToast = false;
  private loginApi = UrlEnum.SHOHAY_AUTH;

  private isAuthenticated = new BehaviorSubject<boolean>(
    !!this.localStorageService.getToken()
  );
  authState$ = this.isAuthenticated.asObservable();

  constructor(
    private readonly utilitiesService: UtilitiesService,
    private readonly httpRequestService: HttpRequestService,
    private readonly localStorageService: LocalStorageService
  ) {
    this.isAuthenticated.next(!!this.localStorageService.getToken());
  }

  public isLoggedIn(): boolean {
    return this.localStorageService.getToken() ? true : false;
  }

  public login(body: LoginBodyModel): Observable<any> {
    return this.httpRequestService
      .post(`${this.loginApi}/account/login`, body, { skipAuth: true })
      .pipe(
        tap((response: any) => {
          if (response && response.token) {
            this.localStorageService.setToken(response.token);
            this.localStorageService.setSessionUser(response.user);
            this.isAuthenticated.next(true);
          }
        })
      );
  }

  public logout() {
    this.localStorageService.deleteToken();
    this.localStorageService.clearSessionUser();
    this.isAuthenticated.next(false); // Emitting false to all subscribers
  }

  public signup(
    body: SignupBodyModel,
    isCorporate: boolean = false
  ): Observable<any> {
    let apiUrl = `${this.loginApi}/users`;
    if (isCorporate) {
      apiUrl = `${apiUrl}/corporate-sign-up`;
    }
    return this.httpRequestService
      .post(apiUrl, body, { skipAuth: true })
      .pipe(map((x: any) => this.utilitiesService.responseHandler(x)));
  }

  public corporateSignup(body: CorporateSignupBodyModel): Observable<any> {
    return this.httpRequestService
      .post(`${this.loginApi}/users/corporate-sign-up`, body)
      .pipe(map((x: any) => this.utilitiesService.responseHandler(x)));
  }

  public changePassword(body: ChangePasswordModel): Observable<any> {
    return this.httpRequestService
      .patch(`${this.loginApi}/account/reset-password`, body)
      .pipe(map((x: any) => this.utilitiesService.responseHandler(x)));
  }

  public resendOtp(body: OtpResendModel): Observable<any> {
    return this.httpRequestService
      .post(`${this.loginApi}/otp/resend`, body, { skipAuth: true })
      .pipe(map((x: any) => this.utilitiesService.responseHandler(x)));
  }

  public validateOtp(body: OtpValidationModel): Observable<any> {
    return this.httpRequestService
      .patch(`${this.loginApi}/otp/validate`, body, { skipAuth: true })
      .pipe(map((x: any) => this.utilitiesService.responseHandler(x)));
  }

  public sendMail(body: ForgetPasswordModel): Observable<any> {
    return this.httpRequestService
      .post(`${this.loginApi}/reset-password/request`, body, { skipAuth: true })
      .pipe(map((x: any) => this.utilitiesService.responseHandler(x)));
  }

  public resetPassword(body: ResetPasswordModel): Observable<any> {
    return this.httpRequestService
      .patch(`${this.loginApi}/reset-password`, body, { skipAuth: true })
      .pipe(map((x: any) => this.utilitiesService.responseHandler(x)));
  }
}
