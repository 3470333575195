import { formatDate } from '@angular/common';
import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ICampaignTeam } from 'src/app/shared/interface/fundriser/fundraiser.interface';
import { AnnouncementModel } from 'src/app/shared/model/announcement.model';
import {
  ApprovalModel,
  CampaignFeaturedAttributeModel,
} from 'src/app/shared/model/approval.model';
import { ApprovalService } from 'src/app/shared/services/approval/approval.service';
import { DonationService } from 'src/app/shared/services/donation/donation.service';
import { FundraiserService } from 'src/app/shared/services/fundraiser/fundraiser.service';
import { LocalStorageService } from 'src/app/shared/services/local-storage/local-storage.service';
import { NgoCampaignService } from 'src/app/shared/services/ngo-campaign/ngo-campaign.service';
import { RichTextService } from 'src/app/shared/services/rich-text/rich-text.service';
import { UtilitiesService } from 'src/app/shared/services/utilities/utilities.service';
import ValidationEngine from 'devextreme/ui/validation_engine';
import {
  ApprovalCategoryEnum,
  ApprovalStatusEnum,
} from 'src/app/shared/enum/approval-category.enum';
import { CampaignStatusEnum } from 'src/app/shared/enum/campaign-status.enum';
import { DxButtonTypes } from 'devextreme-angular/ui/button';
import { GalleryService } from 'src/app/shared/services/galley/gallery.service';

@Component({
  selector: 'app-campain-summary',
  templateUrl: './campain-summary.component.html',
  styleUrl: './campain-summary.component.scss',
})
export class CampainSummaryComponent {
  @Input() ngo_id: string | null = null;
  @Input() project_id: string | null = null;
  @Input() campaign_id: string | null = null;
  @Input() superAdminDashboard: boolean = false;

  public buttonText: string = 'API_wait';
  public buttonImage: string =
    '../../../../assets/images/icon/gg_play-stop-r.svg';
  public tab: number = 1;
  public tabHeader: string = 'Response';

  types: string[] = ['line', 'stackedline', 'fullstackedline'];
  public activeTab: number = 0;
  public selectedTeam: any = null;
  public selectedTeamName: string = '';
  public uploadedFile: any[] = [];
  public uploadUrl = '';

  public cover_image: any;
  public dayLeft: number;

  public teams: ICampaignTeam[] = [];
  public fundRaiser: any[] = [];

  public donationSummary: any = null;

  public lastWeekData: any[] = [];
  public last15DaysData: any[] = [];
  public lastMonthData: any[] = [];
  public totalDonationAmount: number = null;
  public totalTransfer: number = null;

  public comment_reply: any[] = [];
  public displayedComments: any[] = [];
  public currentIndex: number = 2;
  public loadMoreDisabled: boolean = false;

  public campaign: any;

  public isDonationApiHasData: boolean = false;
  public isTeamHasData: boolean = false;
  public isFundRaiserHasData: boolean = false;
  public isCommentHasData: boolean = false;
  public chartData: any = [];
  public selectedPeriod = 'lastMonth';

  public showAnnouncement = false;
  public lineChartDays = '30 Days';
  public announcementModel: AnnouncementModel = new AnnouncementModel();

  public timeLeftForCampaign: any;
  public announcement: any;
  public announcementUpdateMode: boolean = false;
  private announcementId: string = null;

  public featureRequestPopup: boolean = false;
  public is_featured: boolean = false;
  public campaign_Title: string;

  public approvalStatusEnum = ApprovalStatusEnum;
  public campaignStatusEnum = CampaignStatusEnum;

  public addToDonorExperience: boolean = false;
  public selectedComment: any;
  public donorExperienceImage: any;

  public openGallery: boolean = false;
  public galleryId: string;
  public galleryImageCount: number = 0;
  public galleryImages: any[] = [];
  public selectedImage: boolean[] = [];

  constructor(
    private readonly router: Router,
    private readonly spinner: NgxSpinnerService,
    private readonly galleryService: GalleryService,
    private readonly approvalService: ApprovalService,
    private readonly richTextService: RichTextService,
    private readonly donationService: DonationService,
    private readonly utilitiesService: UtilitiesService,
    private readonly fundraiserService: FundraiserService,
    private readonly ngoCampaignService: NgoCampaignService,
    private readonly localStorageService: LocalStorageService
  ) {}

  ngOnInit(): void {
    this.getCampaignDonationById(
      this.ngo_id,
      this.project_id,
      this.campaign_id
    );
    this.getAllTeams();
    this.getAllFundraisers();
    this.lineChartDataCollection();
    this.getComments(this.campaign_id);
    this.getCampaignAnnouncement();
    this.getGalleryLength();
  }

  public getGalleryItems(e: any): void {
    this.galleryImageCount = e;
    this.tabHeader = 'Assets(' + this.galleryImageCount + ')';
  }

  private getGalleryLength(): void {
    this.galleryService
      .getGalleryItemCount(this.campaign_id)
      .subscribe((res: any) => {
        this.galleryId = res.id;
        this.galleryImageCount = res?.item_count;
        this.getGalleryImages();
      });
  }

  private getGalleryImages(): void {
    if (this.galleryId) {
      this.galleryService.getGallery(this.galleryId).subscribe((res: any) => {
        this.galleryImages = res;
        this.galleryImageCount = res?.length;
        this.selectedImage = Array(this.galleryImages?.length).fill(false);
      });
    }
  }

  public getCampaignDonationById(
    ngo_id: string,
    project_id: string,
    campaign_id: string
  ) {
    this.utilitiesService.showSpinner(true);
    this.donationService
      .getDonationCampaignById(ngo_id, project_id, campaign_id)
      .subscribe({
        next: (result) => {
          this.onSuccess(result);
        },
        error: (err) => {
          this.utilitiesService.showSpinner(false);
        },
      });
  }

  private onSuccess(result: any) {
    this.utilitiesService.showSpinner(false);
    if (result) {
      this.campaign = result;
      this.campaign_Title = result.title;
      this.is_featured = result.is_featured;

      if (this.campaign.status === this.campaignStatusEnum.STOPPED) {
        this.buttonText = 'START';
        this.buttonImage = '../../../../assets/images/icon/pause_circle.svg';
      } else if (this.campaign.status === this.campaignStatusEnum.RUNNING) {
        this.buttonText = 'STOP';
        this.buttonImage = '../../../../assets/images/icon/gg_play-stop-r.svg';
      }

      this.timeLeftForCampaign = Math.floor(
        new Date().getDate() - new Date(result.created_at).getDate()
      );

      if (this.timeLeftForCampaign < 0) {
        this.timeLeftForCampaign = 0;
      }

      this.cover_image = JSON.parse(this.campaign?.cover_img_vdo);
      this.donationSummary = JSON.parse(result.donation_summary);

      if (this.donationSummary) {
        if (
          this.donationSummary.recent_donation &&
          this.donationSummary.top_donation &&
          this.donationSummary.first_donation
        ) {
          this.isDonationApiHasData = true;
        }
      }

      this.getDaysLeft(this.campaign.end_date);
    }
  }

  public lineChartDataCollection() {
    this.utilitiesService.showSpinner(true);
    this.donationService
      .getDonationListByCampaign(this.ngo_id, this.project_id, this.campaign_id)
      .subscribe({
        next: (result) => {
          this.donationListSuccess(result);
        },
        error: (err) => {
          this.utilitiesService.showSpinner(false);
        },
      });
  }

  private donationListSuccess(result: any) {
    this.utilitiesService.showSpinner(false);
    this.totalDonationAmount = result.total_donation;
    this.totalTransfer = result.donations.length;
    this.lastWeekData = this.filterAndAggregateDonations(result.donations, 7);
    this.last15DaysData = this.filterAndAggregateDonations(
      result.donations,
      15
    );
    this.lastMonthData = this.filterAndAggregateDonations(result.donations, 30);
    this.chartData = this.lastMonthData;
  }

  public filterAndAggregateDonations(donations, days: number) {
    const aggregatedData = {};
    const currentDate = new Date();
    let totalAmountInTime = 0;
    for (const donation of donations) {
      const donationDate = new Date(donation.created_at);
      const diffInDays = Math.floor(
        (currentDate.getTime() - donationDate.getTime()) / (1000 * 60 * 60 * 24)
      );

      if (diffInDays <= days) {
        const formattedDate = formatDate(donationDate, 'dd MMM', 'en-US');
        if (!aggregatedData[formattedDate]) {
          aggregatedData[formattedDate] = donation.amount;
        } else {
          aggregatedData[formattedDate] += donation.amount;
        }

        totalAmountInTime += donation.amount;
      }
    }
    const aggregatedArray = Object.keys(aggregatedData).map((date) => ({
      date,
      amount: aggregatedData[date],
    }));

    aggregatedArray.sort((a, b) => {
      const dateA = new Date(`${a.date} ${currentDate.getFullYear()}`);
      const dateB = new Date(`${b.date} ${currentDate.getFullYear()}`);
      return dateA.getTime() - dateB.getTime();
    });

    aggregatedArray.push({ date: 'Total', amount: totalAmountInTime });
    return aggregatedArray;
  }

  public onTimePeriodChange(e: any) {
    const selectedPeriod = e.value;
    if (selectedPeriod === 'lastWeek') {
      this.chartData = this.lastWeekData;
      this.lineChartDays = '7 days';
    } else if (selectedPeriod === 'last15Days') {
      this.chartData = this.last15DaysData;
      this.lineChartDays = '15 Days';
    } else if (selectedPeriod === 'lastMonth') {
      this.chartData = this.lastMonthData;
      this.lineChartDays = '30 Days';
    }
  }

  private getAllTeams(): void {
    this.spinner.show();
    this.fundraiserService.getAllTeams(this.campaign_id).subscribe({
      next: (result: ICampaignTeam[]) => {
        this.spinner.hide();
        if (result?.length > 0) {
          this.isTeamHasData = true;
          this.teams = this.modifyTeamsList(result).sort(
            (a, b) => a.fund_raised - b.fund_raised
          );
        }
      },
      error: (error: any) => {
        this.spinner.hide();
        this.utilitiesService.showSwalWithToast(
          'Error',
          error?.error?.message,
          'error'
        );
      },
    });
  }

  private getAllFundraisers(): void {
    this.spinner.show();
    this.fundraiserService
      .getAllTeamsWithFundraisers(this.campaign_id)
      .subscribe({
        next: (result: any) => {
          this.spinner.hide();
          if (result?.length > 0) {
            this.isFundRaiserHasData = true;
            this.fundRaiser = this.modifyTeamsList(result).sort(
              (a, b) => a.fund_raised - b.fund_raised
            );
            this.selectTeam(this.fundRaiser[0]);
          }
        },
        error: (error: any) => {
          this.spinner.hide();
          this.utilitiesService.showSwalWithToast(
            'Error',
            error?.error?.message,
            'error'
          );
        },
      });
  }

  private modifyTeamsList(result: any[]): any[] {
    result.forEach((item: any) => {
      item.team_logo = item.team_logo ? JSON.parse(item.team_logo) : null;
      if (item.members) {
        item.members.forEach((member: any) => {
          member.profile_img = item.profile_img
            ? JSON.parse(item.profile_img)
            : null;
        });
      }
    });
    return result;
  }

  public async toggleButton() {
    if (this.buttonText === 'START') {
      await this.startCampaign();
    } else if (this.buttonText === 'STOP') {
      await this.stopCampaign();
    }
  }

  public toggleReply(commentId: string): void {
    this.displayedComments = this.displayedComments.map((comment) =>
      comment.id === commentId
        ? { ...comment, showReplyField: !comment.showReplyField }
        : comment
    );
  }

  public selectTeam(team: any): void {
    this.selectedTeam = team;
    this.selectedTeam.members.sort((a, b) => a.updated_at - b.updated_at);
  }

  public onTabChange(event: any) {
    this.activeTab = event.component.option('selectedIndex');
  }

  public onFileUploaded(e: any): void {}

  private getDaysLeft(endDate: string) {
    const now = new Date().getDate();
    const end = new Date(endDate).getDate();

    if (end >= now) {
      this.dayLeft = Math.ceil((end - now) / (1000 * 3600 * 24));
    } else {
      this.dayLeft = 0;
    }
  }

  // ..................
  public openAnnouncementPopup(): void {
    this.showAnnouncement = true;
  }

  public async submitAnnouncement() {
    const { isValid } = ValidationEngine.validateGroup(
      'announcementValidationGrp'
    );
    if (isValid) {
      this.announcementModel.ngo_id = this.ngo_id;
      this.announcementModel.project_id = this.project_id;
      this.announcementModel.campaign_id = this.campaign_id;

      if (this.announcementUpdateMode) {
        this.updateCampaignAnnouncement();
      } else {
        try {
          //this.spinner.show();
          const richTextBody = await this.richTextService.processHtmlString(
            this.announcementModel.body
          );
          this.announcementModel.body = richTextBody;

          this.addAnnouncement();
        } catch (err) {
          this.spinner.hide();
          this.utilitiesService.showSwalWithToast(
            err.error.error_name,
            err.error.message,
            'error'
          );
        }
      }
    }
  }

  private getCampaignAnnouncement(): void {
    this.ngoCampaignService
      .getCampaignAnnouncement(this.campaign_id)
      .subscribe((result: any) => {
        if (result.length > 0) {
          this.announcementUpdateMode = true;
          this.announcement = result[0];
          this.announcement.body = this.richTextService.decodeHtml(
            this.announcement.body
          );
          this.announcementModel.title = this.announcement.title;
          this.announcementModel.body = this.announcement.body;
          this.announcementId = this.announcement.id;
        }
      });
  }

  private async updateCampaignAnnouncement() {
    const richTextBody = await this.richTextService.processHtmlString(
      this.announcementModel.body
    );
    const body = {
      title: this.announcementModel.title,
      body: richTextBody,
    };
    this.ngoCampaignService
      .updateCampaignAnnouncement(body, this.announcementId)
      .subscribe({
        next: (result: any) => {
          this.announcement.title = this.announcementModel.title;
          this.announcement.body = this.richTextService.decodeHtml(
            this.announcementModel.body
          );
          this.showAnnouncement = false;
        },
      });
  }

  public deleteAnnouncement(): void {
    this.ngoCampaignService
      .deleteCampaignAnnouncement(this.announcementId)
      .subscribe({
        next: (result: any) => {
          this.utilitiesService.showSwalWithToast(
            'Successful',
            'Announcement deleted successfully',
            'success'
          );
          this.showAnnouncement = false;
          this.announcementModel = new AnnouncementModel();
          this.announcementUpdateMode = false;
        },
      });
  }

  private addAnnouncement(): void {
    this.ngoCampaignService
      .createCampaignAnnouncement(this.announcementModel)
      .subscribe({
        next: (result: any) => {
          this.showAnnouncement = false;
          this.getCampaignAnnouncement();
        },
      });
  }

  public getComments(campaign_id: string) {
    this.donationService
      .getDonationCampaignCommentReply(campaign_id)
      .subscribe({
        next: (result) => {
          if (result.length > 0) {
            this.isCommentHasData = true;
            this.comment_reply = result.map((comment: any) => ({
              ...comment,
              isAnonymous: false,
              showReplyField: true,
              replyText: null,
              replies: comment.replies.map((reply: any) => ({
                ...reply,
                isAnonymous: false,
              })),
            }));
          }
          this.displayedComments = this.comment_reply.slice(
            0,
            this.currentIndex
          );
          if (this.currentIndex >= this.comment_reply.length) {
            this.loadMoreDisabled = true;
          }
        },
      });
  }

  public loadMoreComments() {
    this.currentIndex += 1;
    this.displayedComments = this.comment_reply.slice(0, this.currentIndex);

    if (this.currentIndex >= this.comment_reply.length) {
      this.loadMoreDisabled = true; // Disable button when all comments are shown
    }
  }

  public sendReply(comment: any) {
    if (comment.replyText) {
      const replyBody = {
        campaign_id: this.campaign_id,
        name: comment.name,
        comment: comment.replyText,
        parent_id: comment.id,
        project_id: this.project_id,
        ngo_id: this.ngo_id,
        donor_id: comment.donor_id,
      };
      this.donationService.makeReply(replyBody).subscribe({
        next: (result) => {
          const newReply = {
            ...result,
            time: result.date,
            text: result.comment,
            isAnonymous: false,
          };
          comment.replies = comment.replies || [];
          comment.replies.push(newReply);
          comment.showReplyField = false;
          comment.replyText = '';
        },
      });
    } else {
      this.utilitiesService.showSwalWithToast(
        'No text found',
        'Reply text is required',
        'warning'
      );
    }
  }

  private async confirmAction(message: string, confirmButtonText: string) {
    return await this.utilitiesService.showSwalAndReturn({
      type: 'warning',
      title: 'Are you sure?',
      message: message,
      confirmButtonText: confirmButtonText,
      showCancelButton: true,
      cancelButtonText: 'Cancel',
    });
  }

  public async deleteFunctionality() {
    this.showAnnouncement = false;
    const response = await this.utilitiesService.showSwalAndReturn({
      type: 'warning',
      title: `Are you sure?`,
      message: 'Do you want to delete this announcement?',
      confirmButtonText: 'Yes',
      showCancelButton: true,
      cancelButtonText: 'No',
    });

    if (response.isConfirmed) {
      this.deleteAnnouncement();
    }
  }

  private async stopCampaign() {
    const response = await this.confirmAction(
      'You want to stop the campaign?',
      'Yes, Stop'
    );
    if (response.isConfirmed) {
      const currentDate = new Date().toISOString();
      this.spinner.show();

      this.ngoCampaignService
        .stopCampaign(
          this.ngo_id,
          this.project_id,
          this.campaign_id,
          currentDate
        )
        .subscribe({
          next: (res) => {
            this.spinner.hide();
            this.utilitiesService.showSwal(
              'success',
              'Campaign stopped successfully!'
            );
            this.buttonText = 'START';
            this.buttonImage =
              '../../../../assets/images/icon/pause_circle.svg';
          },
          error: (err) => {
            this.spinner.hide();
            this.utilitiesService.showSwal(
              'error',
              'Error stopped the campaign'
            );
          },
        });
    }
  }

  private async startCampaign() {
    const response = await this.confirmAction(
      'You want to start the campaign?',
      'Yes, Start'
    );
    if (response.isConfirmed) {
      const currentDate = new Date().toISOString();
      this.spinner.show();

      this.ngoCampaignService
        .startCampaign(
          this.ngo_id,
          this.project_id,
          this.campaign_id,
          currentDate
        )
        .subscribe({
          next: (res) => {
            this.spinner.hide();
            this.utilitiesService.showSwal(
              'success',
              'Campaign started successfully!'
            );
            this.buttonText = 'STOP';
            this.buttonImage =
              '../../../../assets/images/icon/gg_play-stop-r.svg';
          },
          error: (err) => {
            this.spinner.hide();
            this.utilitiesService.showSwal(
              'error',
              'Error starting the campaign'
            );
          },
        });
    }
  }

  public async featureRequest() {
    const title = this.is_featured
      ? 'This campaign will be removed from the featured section.'
      : 'This campaign will be highlighted in the featured section.';
    const response = await this.utilitiesService.showSwalAndReturn({
      title: title,
      type: this.is_featured ? 'error' : 'warning',
      confirmButtonText: 'Continue',
      showCancelButton: true,
      cancelButtonText: 'Cancel',
    });

    if (response.isConfirmed) {
      const user: any = this.localStorageService.getSessionUser();
      const body: ApprovalModel = new ApprovalModel(
        ApprovalCategoryEnum.FEATURED_CAMPAIGN
      );

      body.ngo_id = this.campaign.ngo_id;
      body.project_id = this.campaign.project_id;
      body.approval_requested_by = user?.id;
      body.approval_category_id = this.campaign.id;
      body.attribute = JSON.stringify(
        new CampaignFeaturedAttributeModel(this.campaign)
      );

      this.approvalService.makeRequest(body).subscribe({
        next: (result: any) => {
          this.is_featured = !this.is_featured;
        },
      });
    }
  }

  public async commentAction(e: any, comment: any) {
    if (e.itemData.id === 'add') {
      this.selectedComment = comment;
      this.addToDonorExperience = true;
    } else if (e.itemData.id === 'delete') {
      const response = await this.confirmAction(
        'You want to delete this comment?',
        'Yes, Delete'
      );
      if (response.isConfirmed) {
        this.deleteComment(comment.campaign_id, comment.id);
      }
    }
  }

  public async addCommentToWall() {
    const response = await this.confirmAction(
      'You want to add this comment to donor experience?',
      'Add'
    );
    if (response.isConfirmed) {
      this.donationService
        .addCommentToExperienceWall(this.selectedComment.id, true)
        .subscribe((result) => {});
    }
  }

  private deleteComment(campaignId: string, commentId: string): void {
    this.donationService
      .deleteComment(campaignId, commentId)
      .subscribe((result) => {
        this.displayedComments = this.displayedComments.filter(
          (c) => c.id !== commentId
        );
        this.comment_reply = this.comment_reply.filter(
          (c) => c.id !== commentId
        );
      });
  }

  public onFileChange(e: any): void {
    const input = e.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      this.donorExperienceImage = input.files[0];
      this.selectedImage = Array(this.galleryImages?.length).fill(false);
      this.openGallery = false;
    }
  }

  public triggerFileInput(inputId: string): void {
    const input = document.getElementById(inputId) as HTMLInputElement;
    if (input) {
      input.click();
    }
  }

  public selectImage(image: any, index: number): void {
    if (this.selectedImage[index]) {
      this.selectedImage[index] = false;
    } else {
      this.donorExperienceImage = image;
      this.selectedImage = Array(this.galleryImages?.length).fill(false);
      this.selectedImage[index] = true;
    }
  }

  public navigateToCreateCampaign() {
    const encryptedParams = this.localStorageService.encryptObjectUrl({
      id: this.campaign_id,
      ngo_id: this.ngo_id,
      project_id: this.project_id,
    });
    this.router.navigate(['/dashboard/manage/campaign/create-campaign'], {
      queryParams: { data: encryptedParams },
    });
  }

  public searchIcon: DxButtonTypes.Properties = {
    icon: './assets/images/icon/search_icon.svg',
    stylingMode: 'text',
    hint: 'Search',
    // onClick: () => {
    //   this.searchCampaign({});
    // },
  };

  public extractedText(text: string): string {
    return text.split('/')[0].toUpperCase();
  }
}
