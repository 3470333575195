<dx-popup
  [(visible)]="showPopup"
  [width]="1000"
  [height]="650"
  [showTitle]="true"
  [showCloseButton]="true"
  [dragEnabled]="false"
  [resizeEnabled]="false"
  position="center"
  [enableBodyScroll]="false"
  [hideOnOutsideClick]="false"
  (onHidden)="clearReceipt()"
>
  <div id="receipt_pdf" class="fs_18">
    <div class="d-flex w-100 justify-content-between align-items-center mb-5">
      <div>
        <img
          width="142px"
          height="35px"
          src="./assets/images/logo/shohay-updated-logo.svg"
        />
      </div>
      <div class="fs_24">
        <span>Payment Receipt: </span>
        <span class="c_0054a6">{{ receipt?.invoice_no }}</span>
      </div>
    </div>

    <div class="fs_24 fw-bold mb-3">Invoice to:</div>

    <div class="d-flex justify-content-between mb-2">
      <div>
        <span class="fw-bold">{{ "Name: " }}</span
        >{{ receipt?.invoice_to }}
      </div>
      <div>
        <span class="fw-bold">{{ "Date: " }}</span
        >{{ receipt?.invoice_date | date : "MMM dd, yyyy" }}
      </div>
    </div>
    <div class="d-flex justify-content-between mb-5">
      <div>
        <span class="fw-bold">{{ receipt?.label2Name }}: </span
        >{{ receipt?.label2Value }}
      </div>
      <div>
        <span
          ><span class="fw-bold">{{ "Payment Method: " }}</span></span
        >
        <span class="method">{{ receipt?.payment_method }}</span>
      </div>
    </div>

    <div class="fs_24 fw-bold c_0054a6 mb-2">{{ receipt?.paid_to }}</div>

    <div class="table_row bc_f2f9ff border-bottom-0">
      <div class="col-3">{{ receipt?.col1Name }}</div>
      <div class="col-7">{{ receipt?.col2Name }}</div>
      <div class="col-2 text-end">{{ receipt?.col3Name }}</div>
    </div>
    <div class="table_row mb-3">
      <div class="col-3 text-dark fw-bold">
        {{ receipt?.col1Value }}
      </div>
      <div class="col-7 text-dark fw-bold">
        <span
          *ngIf="getType(receipt?.col2Value) === 'number'"
          class="text-dark fw-bolder"
          >৳
        </span>
        {{ receipt?.col2Value }}
      </div>
      <div class="text-dark fw-bold col-2 text-end">
        <span class="text-dark fw-bolder">৳ </span>
        {{ receipt?.col3Value | number }}
      </div>
    </div>

    <div class="d-flex justify-content-end">
      <div class="total">
        <div class="fs_18 fw-bolder">Total</div>
        <div class="fs_16 fw-bolder c_0054a6">
          <span class="fw-bolder">৳ </span>
          {{ receipt?.total_amount | number }}
        </div>
      </div>
    </div>
  </div>

  <div class="d-flex justify-content-end mt-5">
    <button class="blue-button-md" (click)="downloadPDF()">Download</button>
  </div>
</dx-popup>
