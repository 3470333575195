import { Component, Input, OnInit } from '@angular/core';
import { ReceiptModel } from '../../model/receipt.model';
import { CommonSharedServiceService } from '../../shared-services/common-shared-service/common-shared-service.service';
import jsPDF from 'jspdf';

@Component({
  selector: 'app-receipts',
  templateUrl: './receipts.component.html',
  styleUrl: './receipts.component.scss',
})
export class ReceiptsComponent implements OnInit {
  public showPopup: boolean = false;
  @Input() receipt: ReceiptModel = {
    invoice_no: '71NXMA9X',
    invoice_to: 'Md. Rashedul Hasan',
    invoice_date: new Date(),
    label2Name: 'Address',
    label2Value: '53, Paltan, Dhaka',
    payment_method: 'Card',
    paid_to: 'Campaigns',
    col1Name: 'Organization',
    col1Value: 'Brac Bangladesh',
    col2Name: 'Campaign',
    col2Value: 'Flood Relief Fund - 2024',
    col3Name: 'Amount',
    col3Value: '10000',
    total_amount: '10000',
  };

  constructor(private readonly sharedService: CommonSharedServiceService) {}

  ngOnInit(): void {
    this.sharedService.receiptPopupState$.subscribe((state) => {
      this.showPopup = state;
    });
  }

  public clearReceipt(): void {
    console.log('clearReceipt');
    this.receipt = new ReceiptModel();
  }

  public getType(value: any): string {
    return typeof value;
  }

  public downloadPDF() {
    const pdf = new jsPDF();
    const content = document.getElementById('receipt_pdf');
    if (content) {
      pdf.html(content, {
        callback: (pdfInstance) => {
          pdfInstance.save('money_receipt.pdf');
        },
        x: 10,
        y: 10,
        width: 190,
        windowWidth: 1100,
      });
    }
  }
}
