import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, map, throwError } from 'rxjs';
import {
  BYPASS_TOKEN,
  CUSTOM_BASE_URL,
  HIDE_SUCCESS_SWAL,
  SHOW_SPINNER,
  SHOW_SUCCESS_SWAL,
} from '../../interceptor/token/token.interceptor';
import { IHttpRequestOptions } from '../../interface/http-request -options.interface';

@Injectable()
export class HttpRequestService {
  constructor(private readonly http: HttpClient) {}

  private getHttpOptions(
    options?: IHttpRequestOptions
  ): Omit<
    IHttpRequestOptions,
    | 'skipAuth'
    | 'baseUrl'
    | 'showSpinner'
    | 'showSuccessSwal'
    | 'hideSuccessSwal'
  > {
    const {
      skipAuth = false,
      baseUrl = null,
      showSpinner = true,
      showSuccessSwal = null,
      hideSuccessSwal = false,
      ...rest
    } = options || {};

    let httpOptions: Omit<
      IHttpRequestOptions,
      | 'skipAuth'
      | 'baseUrl'
      | 'showSpinner'
      | 'showSuccessSwal'
      | 'hideSuccessSwal'
    > = rest;

    const context = options?.context || new HttpContext();

    if (skipAuth) {
      context.set(BYPASS_TOKEN, true);
    }
    if (baseUrl) {
      context.set(CUSTOM_BASE_URL, baseUrl);
    }
    if (showSpinner) {
      context.set(SHOW_SPINNER, showSpinner);
    }
    if (showSuccessSwal) {
      context.set(SHOW_SUCCESS_SWAL, showSuccessSwal);
    }
    if (hideSuccessSwal) {
      context.set(HIDE_SUCCESS_SWAL, hideSuccessSwal);
    }

    httpOptions = {
      ...rest,
      context,
    };

    return httpOptions;
  }

  public get<T>(url: string, options?: IHttpRequestOptions): Observable<T> {
    const httpOptions = this.getHttpOptions(options);

    return this.http.get<T>(url, httpOptions as any).pipe(
      map((x: any) => x),
      catchError((err) => {
        return throwError(() => err);
      })
    );
  }

  public post<T>(
    url: string,
    body: any,
    options?: IHttpRequestOptions
  ): Observable<T> {
    const httpOptions = this.getHttpOptions(options);
    return this.http.post<T>(url, body, httpOptions as any).pipe(
      map((x: any) => x),
      catchError((err) => {
        return throwError(err);
      })
    );
  }

  public put<T>(
    url: string,
    body: any,
    options?: IHttpRequestOptions
  ): Observable<T> {
    const httpOptions = this.getHttpOptions(options);
    return this.http.put<T>(url, body, httpOptions as any).pipe(
      map((x: any) => x),
      catchError((err) => {
        return throwError(err);
      })
    );
  }

  public delete<T>(url: string, options?: IHttpRequestOptions): Observable<T> {
    const httpOptions = this.getHttpOptions(options);
    return this.http.delete<T>(url, httpOptions as any).pipe(
      map((x: any) => x),
      catchError((err) => {
        return throwError(err);
      })
    );
  }

  public patch<T>(
    url: string,
    body: any,
    options?: IHttpRequestOptions
  ): Observable<T> {
    const httpOptions = this.getHttpOptions(options);
    return this.http.patch<T>(url, body, httpOptions as any).pipe(
      map((x: any) => x),
      catchError((err) => {
        return throwError(err);
      })
    );
  }
}
