<div class="d-flex w-100 flex-column">
  <div class="d-flex justify-content-between w-100 mb-4">
    <div class="d-flex gap-2 align-items-center">
      @if(gallery?.length > 0) {
      <div class="font_24">Action:</div>
      <dx-drop-down-button
        class="action_button"
        [text]="actionTitle"
        [dropDownOptions]="{ width: 130 }"
        displayExpr="text"
        keyExpr="text"
        [useItemTextAsTitle]="true"
        [items]="[
          { id: 'delete', text: 'Delete' },
          { id: 'share', text: 'Share', disabled: true },
          { id: 'download', text: 'Download', disabled: true }
        ]"
        (onItemClick)="changeActionTitle($event)"
      ></dx-drop-down-button>
      <button class="blue-button-md" (click)="action()">Apply</button>
      }
    </div>
    @if(isGalleryPresent) {
    <div>
      <button class="blue-button-md" (click)="triggerFileInput('imageInput')">
        <img
          style="margin-bottom: 3px; margin-right: 4px"
          src="/assets/images/icon/add_square_white_icon.svg"
        />
        Add New Asset
      </button>
      <input
        #imageInput
        type="file"
        class="d-none"
        id="imageInput"
        (change)="onFileChange($event)"
        aria-hidden="true"
        multiple="true"
        accept=".jpg, .jpeg, .png"
      />
    </div>
    }
  </div>

  @if(!isGalleryPresent) {
  <div
    *ngIf="gallery?.length === 0"
    class="w-100 align-items-center d-flex flex-column gap-3 mt-5"
  >
    <img class="mt-5" src="./assets/images/photo.png" />
    <div>Create your gallery first to add media assets.</div>
    <button class="blue-button-md" (click)="createGallery()">
      <img
        style="margin-bottom: 3px; margin-right: 4px"
        src="/assets/images/icon/add_square_white_icon.svg"
      />
      Create Gallery
    </button>
  </div>
  } @if(isGalleryPresent) {
  <div
    *ngIf="gallery?.length === 0"
    class="w-100 align-items-center d-flex flex-column gap-3 mt-5"
  >
    <img class="mt-5" src="./assets/images/photo.png" />
    <div>You didn’t add any donation campaign Photo in gallery.</div>
    <button class="blue-button-md" (click)="triggerFileInput('imageInput')">
      <img
        style="margin-bottom: 3px; margin-right: 4px"
        src="/assets/images/icon/add_square_white_icon.svg"
      />
      Add New Asset
    </button>
    <input
      #imageInput
      type="file"
      class="d-none"
      id="imageInput"
      (change)="onFileChange($event)"
      aria-hidden="true"
      multiple="true"
      accept=".jpg, .jpeg, .png"
    />
  </div>

  <div class="d-flex row">
    <div
      class="col-sm-12 col-lg-6 col-xl-4 mb-4"
      *ngFor="let image of gallery; let g_i = index"
    >
      <div class="select_image">
        <div
          class="image"
          [ngStyle]="{
            'background-image': 'url(' + image?.url + ')'
          }"
        >
          <div
            *ngIf="!selectedImages[g_i]"
            class="select cursor"
            (click)="selectedImages[g_i] = true"
          ></div>
          <img
            *ngIf="selectedImages[g_i]"
            class="select border-none cursor"
            src="./assets/images/icon/select_icon_yellow.svg"
            (click)="selectedImages[g_i] = false"
          />
        </div>
        <div class="d-flex justify-content-between">
          <div>
            <div class="font_22">{{ image?.originalname }}</div>
            <div class="png">
              <span>{{ image?.resource_type }}</span>
              <!-- - <span>1500x800</span> -->
            </div>
          </div>
          <div class="image_video">{{ extractedText(image?.mimetype) }}</div>
        </div>
      </div>
    </div>
  </div>
  }
</div>
