<div class="main_div">
  <div>{{ currentUrl }}</div>
  <div class="second_div">
    <img
      class="click"
      title="Info"
      src="./assets/images/icon/info_icon_inactive.svg"
    />
    @if(isBookmarkPage){
    <img
      class="click"
      title="Bookmarked NGOs"
      (click)="bookmarkedClicked()"
      src="./assets/images/icon/bookmark_icon_active.svg"
    />
    }@else {
    <img
      class="click"
      title="Bookmarked NGOs"
      (click)="bookmarkedClicked()"
      src="./assets/images/icon/bookmark_icon_inactive.svg"
    />
    }

    <div *ngIf="notifications?.length === 0" class="dropdown">
      <img
        class="click dropdown-toggle"
        title="Notifications"
        id="dropdownMenuButton1"
        data-bs-toggle="dropdown"
        aria-expanded="false"
        src="./assets/images/icon/notification_icon_inactive.svg"
      />
      <ul
        class="dropdown-menu no_notification_dropdown"
        aria-labelledby="dropdownMenuButton1"
      >
        <div class="notification_title_div">
          <div>Notification</div>
          <div class="mark_as_read_inactive">Mark all as read</div>
        </div>
        <div class="no_notification_div">
          <img src="./assets/images/icon/notification_off_icon.svg" />
          <div class="no_notification">No Notification Yet</div>
        </div>
      </ul>
    </div>

    <!-- <svg
      *ngIf="notifications?.length > 0"
      width="34"
      height="34"
      class="click"
      style="margin-bottom: 1px"
      xmlns="http://www.w3.org/2000/svg"
      (click)="openNotification()"
    >
      <g>
        <path
          fill="#686868"
          d="m20.570406,27.939824c0.8218,-0.0824 1.3772,0.79 0.9889,1.4963c-0.2736,0.4977 -0.6592,0.932 -1.1211,1.2878c-0.471,0.3627 -1.0176,0.6411 -1.6038,0.828c-0.5866,0.1872 -1.2094,0.2819 -1.8344,0.2819c-0.6251,0 -1.2479,-0.0947 -1.8344,-0.2819c-0.5863,-0.1869 -1.1328,-0.4653 -1.6038,-0.828c-0.462,-0.3558 -0.8476,-0.7901 -1.1211,-1.2878c-0.3883,-0.7063 0.167,-1.5787 0.9889,-1.4963c0.2563,0.0257 2.2625,0.2219 3.5704,0.2219c1.3078,0 3.314,-0.1962 3.5704,-0.2219z"
        />
        <path
          fill="#686868"
          d="m21.658987,4.147954c-3.061,-1.35969 -6.5988,-1.31056 -9.6177,0.13357c-3.00263,1.43631 -4.90088,4.38247 -4.90088,7.6064l0,1.6981c0,1.5096 -0.39131,2.9953 -1.13856,4.323l-0.30272,0.5378c-1.86316,3.3104 0.1738,7.405 4.02406,8.089c4.6999,0.8351 9.5194,0.8351 14.2194,0l0.2142,-0.038c3.7992,-0.675 5.9158,-4.613 4.2905,-7.9821l-0.3539,-0.7336c-0.5927,-1.2283 -0.8996,-2.566 -0.8996,-3.92l0,-0.4288c-0.6435,0.3785 -1.3933,0.5956 -2.1939,0.5956c-2.3932,0 -4.3333,-1.9401 -4.3333,-4.33337c0,-2.02836 1.3938,-3.73123 3.2756,-4.20332c-0.6023,-0.48379 -1.2757,-0.89678 -2.0096,-1.22276l-0.2736,-0.12152z"
        />
        <path
          fill="#686868"
          d="m18.165358,11.5312c1.841,0 3.3334,-1.4924 3.3334,-3.33337c0,-1.84094 -1.4924,-3.33333 -3.3334,-3.33333c-1.8409,0 -3.3333,1.49239 -3.3333,3.33333c0,1.84097 1.4924,3.33337 3.3333,3.33337z"
        />
        <circle fill="#D92D20" r="8" cy="10" cx="24" />
        <text
          id="notification-number"
          x="24"
          y="12"
          font-size="8"
          text-anchor="middle"
          fill="white"
        >
          {{ unreadNotifications }}
        </text>
      </g>
    </svg> -->

    <div class="dropdown">
      <button
        class="btn btn-secondary dropdown-toggle custom-dropdown-toggle"
        type="button"
        [title]="userName"
        id="dropdownMenuButton3"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        {{ userNameFirstLetter }}
      </button>
      <ul
        class="dropdown-menu dropdown_menu setting_parent_div"
        aria-labelledby="dropdownMenuButton3"
      >
        <div class="setting">
          <span>Settings</span>
        </div>
        <hr class="dropdown-divider" />
        <div class="account_info_parent">
          <div class="account_text">Account</div>

          <div class="user_details_div">
            <img src="./assets/images/icon/profile_icon_blue.svg" />
            <div class="user_info">
              <span style="font-weight: 700">{{ userName }}</span>
              <span style="font-weight: 500">{{ userEmail }}</span>
            </div>
          </div>

          <div class="manage_account">
            <span>Manage Account</span>
            <img
              class="cursor"
              src="./assets/images/icon/edit_icon_black.svg"
            />
          </div>
        </div>

        <hr class="dropdown-divider mb-4" />
        <li>
          <a class="dropdown-item" [routerLink]="'./my-profile'"
            ><img class="c" src="./assets/images/icon/person.svg" /> My
            Profile</a
          >
        </li>
        <li><hr class="dropdown-divider" /></li>
        <li>
          <a class="dropdown-item" [routerLink]="'./change-password'"
            ><img
              class="c"
              src="./assets/images/icon/settings_icon_inactive.svg"
            />
            Change Password</a
          >
        </li>
        <li><hr class="dropdown-divider" /></li>
        <li>
          <a class="dropdown-item" [routerLink]="'./bookmarked-ngos'"
            ><img
              class="c"
              src="./assets/images/icon/bookmark_white_icon.svg"
            />
            Bookmarked NGOs</a
          >
        </li>
        <li><hr class="dropdown-divider" /></li>
        <li>
          <a class="dropdown-item d-flex justify-content-between" href="#">
            <div>
              <img
                class="c"
                src="./assets/images/icon/notification_icon_white.svg"
              />
              Notifications
            </div>
            <button class="yellow-button-sm">New</button></a
          >
        </li>
        <li><hr class="dropdown-divider" /></li>
        <li>
          <a class="dropdown-item" href="#"
            ><img class="c" src="./assets/images/icon/help_icon_white.svg" />
            Help</a
          >
        </li>
        <li><hr class="dropdown-divider mb-4" /></li>
        <li>
          <a class="dropdown-item mb-3 cursor" (click)="logOut()"
            ><img class="c" src="./assets/images/icon/logout_icon_white.svg" />
            Log out</a
          >
        </li>
        <!-- <li><a class="dropdown-item" href="#">Something else here</a></li>    -->
        <!-- here -->
      </ul>
    </div>
  </div>
</div>

<dx-popup
  [visible]="showNotification"
  [width]="'525px'"
  height="auto"
  [minHeight]="'491px'"
  [maxHeight]="'813px'"
  [showTitle]="false"
  [dragEnabled]="false"
  [hideOnOutsideClick]="true"
  [showCloseButton]="false"
  [shading]="false"
>
  <dxo-position
    at="right top"
    my="right top"
    of="window"
    offset="-33 77"
    collision="fit"
  ></dxo-position>
  <div class="">
    <div class="notification_title_div">
      <div>Notification</div>
      <div
        [ngClass]="isAllNotRead ? 'not_all_read' : 'mark_as_read_inactive'"
        (click)="markAllAsRead()"
      >
        Mark all as read
      </div>
    </div>
    <div class="notification_item_div">
      <div
        class="item_section"
        *ngFor="let day of notifications; let d = index"
      >
        <div class="day">Today</div>
        <div
          class="item"
          *ngFor="let item of day; let i = index"
          (click)="notificationRead(d, i)"
        >
          <div
            [ngClass]="
              notifications[d][i]?.read
                ? 'profile_content_section read'
                : 'profile_content_section'
            "
          >
            <div>
              <img
                class="item_profile"
                [src]="'./assets/images/icon/bookmark_icon_inactive.svg'"
              />
            </div>
            <div class="item_name">Issac Morgan commented on your article.</div>
          </div>
          <div
            [ngClass]="
              notifications[d][i]?.read ? 'item_time read' : 'item_time'
            "
          >
            9.25 PM
          </div>
        </div>
      </div>
    </div>
  </div>
</dx-popup>
