import { GalleryService } from './../../../services/galley/gallery.service';
import { FileService } from 'src/app/shared/services/file/file.service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { GalleryModel } from 'src/app/shared/model/gallery.model';
import { ActivatedRoute } from '@angular/router';
import { LocalStorageService } from 'src/app/shared/services/local-storage/local-storage.service';
import { UtilitiesService } from 'src/app/shared/services/utilities/utilities.service';

@Component({
  selector: 'app-campaign-gallery',
  templateUrl: './campaign-gallery.component.html',
  styleUrl: './campaign-gallery.component.scss',
})
export class CampaignGalleryComponent implements OnInit {
  public actionTitle: string = '';

  public ngoId: string;
  public projectId: string;

  private companyId: string;
  private title: string;

  private campaignId: string;
  @Input() galleryId: string;
  @Output() galleryItems = new EventEmitter<number>();

  public isGalleryPresent: boolean = true;
  private galleryModel: GalleryModel = new GalleryModel();
  public gallery: any[] = [];
  private inputImageList: any;
  public selectedImages: boolean[] = [];
  private selectedImagesId: string[] = [];

  constructor(
    private readonly route: ActivatedRoute,
    private readonly fileService: FileService,
    private readonly galleryService: GalleryService,
    private readonly utilitiesService: UtilitiesService,
    private readonly localStorageService: LocalStorageService
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      const encryptedString = params['data'];
      const decryptedParams =
        this.localStorageService.decryptObjectUrl(encryptedString);
      if (decryptedParams) {
        this.ngoId = decryptedParams?.ngo_id;
        this.projectId = decryptedParams?.project_id;

        this.companyId = decryptedParams?.company_id;
        this.campaignId = decryptedParams?.campaign_id || decryptedParams?.id;
        this.title = decryptedParams?.title;

        this.getCampaignGalleryItemCount();
      }
    });

    if (this.galleryId) {
      this.getGalleryImages();
    }
  }

  private getCampaignGalleryItemCount(): void {
    this.galleryService
      .getGalleryItemCount(this.campaignId)
      .subscribe((result: any) => {
        if (result) {
          this.galleryId = result.id;
          this.getGalleryImages();
        } else {
          this.isGalleryPresent = false;
        }
      });
  }

  public async createGallery() {
    const response = await this.confirmAction(
      'You want to create a new gallery?',
      'Create'
    );

    if (response?.isConfirmed) {
      if (this.ngoId) {
        this.galleryModel.ngo_id = this.ngoId;
        this.galleryModel.project_id = this.projectId;
      } else if (this.companyId) {
        this.galleryModel.ngo_id = this.companyId;
      }
      this.galleryModel.title = this.title;
      this.galleryModel.campaign_id = this.campaignId;

      this.galleryService
        .createGallery(this.galleryModel)
        .subscribe((result: any) => {
          this.isGalleryPresent = true;
        });
    }
  }

  private getGalleryImages(): void {
    this.galleryService.getGallery(this.galleryId).subscribe((res: any) => {
      this.gallery = res;
      this.galleryItems.emit(this.gallery?.length);
      this.selectedImages = Array(this.gallery?.length).fill(false);
      this.selectedImagesId = [];
    });
  }

  public onFileChange(e: any): void {
    const input = e.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      this.inputImageList = input.files;
      this.addGalleryImages();
    }
  }

  public async addGalleryImages() {
    const response = await this.confirmAction(
      `You want to add the assets?`,
      'Yes'
    );

    if (response?.isConfirmed) {
      this.fileService
        .uploadGalleryFiles(this.inputImageList, this.galleryId)
        .subscribe((res: any) => {
          this.getGalleryImages();
        });
    } else {
      this.selectedImages = Array(this.gallery?.length).fill(false);
      this.selectedImagesId = [];
    }
  }

  public changeActionTitle(e: any): void {
    this.actionTitle = e.itemData.text;
  }

  public async action() {
    const response = await this.confirmAction(
      `You want to ${this.actionTitle} the assets?`,
      'Yes'
    );

    if (response?.isConfirmed) {
      this.selectedImagesId = this.gallery
        .filter((_, index) => this.selectedImages[index])
        .map((obj) => obj.file_id);
      if (this.selectedImagesId?.length > 0) {
        this[this.actionTitle.toLowerCase() + 'Images']();
      }
    }
  }

  private deleteImages(): void {
    this.fileService
      .deleteFileMultiple(this.selectedImagesId)
      .subscribe((res: any) => {
        this.getGalleryImages();
      });
  }

  private shareImages(): void {
    console.log('shareImages');
  }
  private downloadImages(): void {
    console.log('downloadImages');
  }

  public triggerFileInput(inputId: string): void {
    const input = document.getElementById(inputId) as HTMLInputElement;
    if (input) {
      input.click();
    }
  }

  public extractedText(text: string): string {
    return text.split('/')[0].toUpperCase();
  }

  private async confirmAction(message: string, confirmButtonText: string) {
    return await this.utilitiesService.showSwalAndReturn({
      type: 'warning',
      title: 'Are you sure?',
      message: message,
      confirmButtonText: confirmButtonText,
      showCancelButton: true,
      cancelButtonText: 'Cancel',
    });
  }
}
