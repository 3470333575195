import { CorporateTypeEnum } from '../../enum/corporate-type.enum';
import { GenderEnum } from '../../enum/gender.enum';

export class SignupBodyModel {
  email: string = null;
  name: string = null;
  company_name: string = null;
  company_designation: string = null;
  contact_number: string = null;
  address: string = null;
  password: string = null;
  profile_img: string = null;
  date_of_birth: Date = null;
  gender: GenderEnum = null;
  corporate_type: CorporateTypeEnum = null;
  company_id: string = null;

  constructor(obj?: any) {
    if (obj) {
      Object.keys(this).forEach((key) => {
        if (obj.hasOwnProperty(key)) {
          this[key] = obj[key];
        }
      });
    }
  }
}

export class CorporateSignupBodyModel {
  email: string = null;
  name: string = null;
  company_id: string = null;
  company_name: string = null;
  company_designation: string = null;
  contact_number: string = null;
  address: string = null;
  password: string = null;
  profile_img: string = null;
  date_of_birth: Date = null;
  gender: GenderEnum = null;
  corporate_type: CorporateTypeEnum = null;

  constructor(obj?: any) {
    if (obj) {
      Object.keys(this).forEach((key) => {
        if (obj.hasOwnProperty(key)) {
          this[key] = obj[key];
        }
      });
    }
  }
}
