<div class="root">
  <header class="counter">
    <div class="status-container">
      @if(!superAdminDashboard){
      <div
        class="counter-wrapper"
        id="counterWrapperContainer"
        [routerLink]="'/dashboard/manage/campaign'"
      >
        <img
          class="arrow-back-icon"
          loading="lazy"
          alt=""
          src="../../../../../../assets/images/icon/arrow_back_icon.svg"
        />
        <a class="back-to-question">Back</a>
      </div>
      }
    </div>

    <div class="more-options">
      <div class="survey-status">
        <b class="employee-satisfaction-survey">{{ campaign_Title }}</b>
        <div class="active-status">
          <div class="active-status-wrapper">
            <a class="active_">Active</a>
          </div>
        </div>
      </div>
    </div>

    <div class="download-button-wrapper-parent">
      @if(!superAdminDashboard){
      <dx-button
        width="96"
        icon="../../../../../../assets/images/icon/edit_icon_black.svg"
        [text]="'Edit'"
        class="header_edit_btn"
        (click)="navigateToCreateCampaign()"
      >
      </dx-button>
      } @if(!superAdminDashboard){
      <dx-button
        icon="../../../../../../assets/images/icon/send_icon_yellow.svg"
        [text]="'Announcement'"
        class="header_dowload_btn"
        (onClick)="openAnnouncementPopup()"
        [disabled]="
          !(
            campaign?.approval_status === approvalStatusEnum.ACCEPTED &&
            campaign?.status === campaignStatusEnum.RUNNING
          )
        "
      >
      </dx-button>
      } @if(superAdminDashboard){
      <dx-button
        icon="../../../../assets/images/icon/yellow-pause-icon.svg"
        [text]="'Suspend'"
        class="header_dowload_btn"
      >
        <!-- (onClick)="openAnnouncementPopup()" -->
      </dx-button>
      }
      <dx-button
        class="toggle-button"
        (click)="toggleButton()"
        stylingMode="contained"
        type="normal"
        [disabled]="superAdminDashboard"
      >
        <img class="social-img" [src]="buttonImage" />
        {{ buttonText }}
      </dx-button>
      <div class="more-vertical">
        <img
          class="more-vertical-icon"
          loading="lazy"
          alt=""
          src="../../../../../../assets/images/icon/more_vertical_icon.svg"
        />
      </div>
    </div>
  </header>

  <section class="response-summary">
    <!-- Header Tab -->
    <div class="response-header">
      <div class="title">{{ tabHeader }}</div>
      <div class="time-value">
        @if(!superAdminDashboard){
        <button
          class="yellow-button-md"
          style="margin-right: 32px"
          (click)="featureRequest()"
        >
          {{ is_featured ? "Remove Featured" : "Request Featured" }}
        </button>
        }
        <button
          class="header-labels"
          [class.active]="tab === 1"
          (click)="tab = 1; tabHeader = 'Responses'"
        >
          <img
            [src]="
              tab === 1
                ? '/assets/images/icon/dashboard_icon_active.svg'
                : '/assets/images/icon/dashboard_icon_inactive.svg'
            "
          />
          Responses
        </button>
        <button
          class="header-labels"
          [class.active]="tab === 2"
          (click)="tab = 2; tabHeader = 'Assets(' + galleryImageCount + ')'"
        >
          <img
            [src]="
              tab === 2
                ? '/assets/images/icon/image_icon_active.svg'
                : '/assets/images/icon/image_icon_inactive.svg'
            "
          />
          Gallery
        </button>
        <button
          class="header-labels"
          [class.active]="tab === 3"
          (click)="tab = 3; tabHeader = 'Details'"
        >
          <img
            [src]="
              tab === 3
                ? '/assets/images/icon/info_active_icon.svg'
                : '/assets/images/icon/info_inactive_icon.svg'
            "
          />
          Details
        </button>
      </div>
    </div>
    <!-- End Header Tab -->
    @if(tab === 1){
    <div class="duration-label-wrapper">
      <div class="average-time-wrapper-parent">
        <div class="responses-counter-wrapper1">
          <div class="total-responses">Total Supporters</div>
          <div class="duration">
            <div class="more-vertical">
              <b class="minutes-value">{{ totalTransfer }}</b>
            </div>
            <div class="job-role-screenshot">
              <div class="minuties">Donation</div>
            </div>
            <img
              class="alarm-icon"
              loading="lazy"
              alt=""
              src="../../../../../../assets/images/icon/person-group.svg"
            />
          </div>
        </div>

        <div class="responses-counter-wrapper1">
          <div class="total-responses">Total Raised</div>
          <div class="duration">
            <div class="more-vertical">
              <b class="minutes-value">৳ {{ totalDonationAmount | number }}</b>
            </div>
            <div class="job-role-screenshot">
              <div class="minuties">BDT</div>
            </div>
            <img
              class="alarm-icon"
              loading="lazy"
              alt=""
              src="../../../../../../assets/images/icon/volunteer_activism.svg"
            />
          </div>
        </div>

        <div class="responses-counter-wrapper1">
          <div class="total-responses">Total Duration left</div>
          <div class="duration">
            <div class="more-vertical">
              <b class="minutes-value">{{ timeLeftForCampaign }}</b>
            </div>
            <div class="job-role-screenshot">
              <div class="minuties">Days</div>
            </div>
            <img
              class="alarm-icon"
              loading="lazy"
              alt=""
              src="../../../../../../assets/images/icon/event.svg"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="individual-overview-parent">
      <div class="individual-overview">
        <div class="rectangle-parent">
          <div class="chart-container">
            <div class="chart_header">
              <div class="abcd text-start">
                <div class="recent_donation">Analytics</div>
                <img
                  loading="lazy"
                  src="../../../../../assets/images/icon/trending_up_icon.svg"
                  alt="Donor"
                  title="donor"
                  class="for_donor"
                />
                <span class="font_16 recent_donated_text"
                  >{{
                    chartData[chartData.length - 1]?.amount | number
                  }}
                  collected in last {{ lineChartDays }}</span
                >
              </div>
              <dx-select-box
                [dataSource]="[
                  { value: 'last15Days', label: 'Last Fortnight' },
                  { value: 'lastWeek', label: 'Last Week' },
                  { value: 'lastMonth', label: 'Last Month' }
                ]"
                displayExpr="label"
                valueExpr="value"
                [(value)]="selectedPeriod"
                (onValueChanged)="onTimePeriodChange($event)"
                class="chart-select"
              ></dx-select-box>
            </div>
            <dx-chart
              [dataSource]="chartData"
              palette="Blue"
              class="line_chart"
            >
              <dxi-series
                argumentField="date"
                valueField="amount"
                name="Amount Donated"
                type="line"
              >
              </dxi-series>
              <dxo-common-series-settings
                argumentField="date"
                type="line"
              ></dxo-common-series-settings>
              <dxo-tooltip [enabled]="true"> </dxo-tooltip>
              <dxo-value-axis>
                <dxo-label> </dxo-label>
              </dxo-value-axis>
              <dxo-argument-axis
                [valueMarginsEnabled]="false"
                discreteAxisDivisionMode="crossLabels"
              >
                <dxo-grid [visible]="true"></dxo-grid>
              </dxo-argument-axis>
              <dxo-legend
                verticalAlignment="bottom"
                horizontalAlignment="center"
                itemTextPosition="bottom"
              >
              </dxo-legend>
              <dxo-export [enabled]="true"></dxo-export>
            </dx-chart>
          </div>
          <!-- comments -->
          @if(isCommentHasData){
          <div class="comments">
            <div class="comments_list">
              <div *ngFor="let comment of displayedComments" class="comment">
                <div class="comment_below">
                  <div class="profile_picture">
                    <img
                      class="c_img"
                      loading="lazy"
                      [src]="
                        comment.isAnonymous
                          ? '../../../../../assets/images/icon/profile_icon_gray.png'
                          : '../../../../../assets/images/icon/profile_icon_gray.png'
                      "
                      alt="profile"
                      title="profile"
                    />
                  </div>
                  <div class="comment_info">
                    <div
                      class="c_name font_18 d-flex justify-content-between"
                      style="font-weight: 600"
                    >
                      <div>
                        {{ comment.isAnonymous ? "Anonymous" : comment.name }}
                        <div class="d-flex gap-2">
                          <span class="font_14" style="font-weight: 600"
                            >৳{{ comment.amount }}</span
                          >
                          <span class="font_14">{{ comment.date }}</span>
                        </div>
                      </div>
                      @if(!superAdminDashboard){
                      <dx-drop-down-button
                        class="dx_dropdown_button"
                        [dropDownOptions]="{ width: 210 }"
                        icon="/assets/images/icon/more_vertical_icon.svg"
                        displayExpr="text"
                        [showArrowIcon]="false"
                        (onItemClick)="commentAction($event, comment)"
                        [items]="[
                          {
                            id: 'add',
                            text: 'Add to Donor Experience',
                            icon: './assets/images/icon/add_comment_icon.svg'
                          },
                          {
                            id: 'delete',
                            text: 'Delete Comment',
                            icon: './assets/images/icon/delete-03.svg'
                          }
                        ]"
                      ></dx-drop-down-button>
                      }
                    </div>
                    <div class="comment_text font_18">
                      <div>
                        {{ comment.comment }}
                      </div>
                    </div>
                    <div class="comment_reply_icon">
                      <div
                        class="icon_and_reply cursor"
                        (click)="toggleReply(comment.id)"
                      >
                        <img
                          loading="lazy"
                          src="../../../../../assets/images/icon/reply.svg"
                          alt="profile"
                          title="profile"
                          height="18px"
                        />
                        <div class="after_icon_reply">Reply</div>
                      </div>
                      <div class="icon_and_reply">
                        <img
                          class="cursor"
                          loading="lazy"
                          src="../../../../../assets/images/icon/favorite.svg"
                          alt="profile"
                          title="profile"
                        />
                        <div class="after_icon_reply cursor">Like</div>
                      </div>
                    </div>
                    <div *ngFor="let reply of comment.replies" class="reply">
                      <div class="reply_below">
                        <div class="profile_picture">
                          <img
                            class="c_img"
                            loading="lazy"
                            [src]="
                              reply.isAnonymous
                                ? '../../../../../assets/images/icon/profile_icon_gray.png'
                                : '../../../../../assets/images/icon/profile_icon_gray.png'
                            "
                            alt="profile"
                            title="profile"
                          />
                        </div>
                        <div class="comment_info_reply">
                          <div class="c_name font_18" style="font-weight: 600">
                            {{ reply.isAnonymous ? "Anonymous" : reply.name }}
                            <span class="font_14">{{ reply.date }}</span>
                          </div>
                          <div class="comment_text_reply font_18">
                            {{ reply.comment }}
                          </div>
                        </div>
                      </div>
                    </div>
                    @if(!superAdminDashboard){
                    <div *ngIf="comment.showReplyField" class="reply_section">
                      <dx-text-area
                        class="reply_body"
                        [autoResizeEnabled]="true"
                        [minHeight]="56"
                        [maxHeight]="200"
                        placeholder="Write a reply"
                        [(ngModel)]="comment.replyText"
                      >
                      </dx-text-area>
                      <div class="send_icon cursor">
                        <img
                          src="../../../../assets/images/icon/send_icon_blue.svg"
                          alt="send_icon"
                          (click)="sendReply(comment)"
                        />
                      </div>
                    </div>
                    }
                  </div>
                </div>
              </div>
              <div class="load_comments_container">
                <dx-button
                  class="blue-button-md"
                  (click)="loadMoreComments()"
                  [disabled]="loadMoreDisabled"
                >
                  Load more Comments
                </dx-button>
              </div>
            </div>
          </div>
          }
        </div>

        <div class="rectangle-parent-right">
          <!-- donor -->

          <app-donation-summary-shared
            [campaign]="campaign"
            [donationSummary]="donationSummary"
            [ngo_id]="ngo_id"
            [project_id]="project_id"
            [ngo_id]="ngo_id"
            [campaign_id]="campaign_id"
            [isCampaignHasData]="isDonationApiHasData"
            (updateCampaign)="getCampaignDonationById(ngo_id,project_id,campaign_id)"
            style="width: 100%"
          ></app-donation-summary-shared>

          <!-- Fundraising Teams List -->
          <div class="fundraising_team_container">
            <dx-tab-panel
              (onSelectionChanged)="onTabChange($event)"
              style="box-shadow: none"
              class="all_tabs"
              [swipeEnabled]="true"
            >
              <dxi-item title="FundRaisers" class="mr-2"> </dxi-item>
              <dxi-item title="Teams"> </dxi-item>
            </dx-tab-panel>
            <div *ngIf="activeTab === 0">
              <div class="fundraiser" style="width: 100%">
                <div class="team_list_">
                  <div class="header_team_">Top Fundraiser</div>
                  @if(isFundRaiserHasData){
                  <div
                    class="teams"
                    *ngFor="let team of fundRaiser; let i = index"
                  >
                    <div class="team" style="margin: 4px">
                      <div class="top_part_">
                        <div class="team_icon_div_">
                          <img
                            [src]="team.team_logo.url"
                            alt="{{ team.team_logo.originalname }}"
                            title="Team logo"
                          />
                        </div>
                        <div class="details_div_">
                          <div class="info_">
                            <div class="font_18" style="font-weight: 700">
                              {{ team.team_name }}
                            </div>
                            <div class="bottam_part">
                              <dx-progress-bar
                                class="progress_team"
                                [min]="0"
                                [max]="team.fundraising_target"
                                [value]="team.fund_raised"
                                [showStatus]="false"
                              >
                              </dx-progress-bar>
                              <div class="bottom_part_">
                                <span class="font_18 fw-bold"
                                  >৳{{ team.fund_raised | number }}</span
                                >
                                <span class="font_16 fw-normal">
                                  {{
                                    (team.fund_raised /
                                      team.fundraising_target) *
                                      100 | number : "1.0-0"
                                  }}% raised of ৳{{
                                    team.fundraising_target | number
                                  }}
                                  Goal
                                </span>
                              </div>
                              <div class="font_18" style="color: #808080">
                                {{ team.members.length }} Supporters{{
                                  team.members.length > 1 ? "s" : ""
                                }}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  }@else {
                  <div class="font_24">No fundraiser is availabel</div>
                  }
                </div>
                <div class="buttons_teams">
                  <div class="white-button-md fw-bold">See All</div>
                  <div class="white-button-md fw-bold">Add Fundraiser</div>
                </div>
              </div>
            </div>
            <div *ngIf="activeTab === 1">
              <div class="fundraiser">
                <div class="team_list">
                  <div class="header_team">Member</div>
                  @if(isTeamHasData){
                  <div class="teams" *ngFor="let team of teams; let i = index">
                    <div class="team" style="margin: 4px">
                      <div class="top_part">
                        <div class="details_div">
                          <div class="team_icon_div">
                            <img
                              [src]="team.team_logo?.url"
                              alt="{{ team.team_logo.originalname }}"
                              title="Team logo"
                            />
                          </div>
                          <div class="info">
                            <div class="font_18" style="font-weight: 700">
                              {{ team.team_name }}
                            </div>
                            <div class="font_18" style="color: #808080">
                              {{ teams.length }} Member{{
                                teams.length > 1 ? "s" : ""
                              }}
                            </div>
                          </div>
                        </div>
                        <img
                          loading="lazy"
                          src="../../../../../assets/images/icon/chevron_right.png"
                          alt="Chevron"
                          style="height: 24px; width: 24px"
                          title="View members"
                        />
                      </div>

                      <div class="bottam_part">
                        <dx-progress-bar
                          class="progress_team"
                          [min]="0"
                          [max]="team.fundraising_target"
                          [value]="team.fund_raised"
                          [showStatus]="false"
                        >
                        </dx-progress-bar>
                        <div class="bottom_part">
                          <span class="font_18 fw-bold"
                            >৳{{ team.fund_raised | number }}</span
                          >
                          <span class="font_16 fw-normal">
                            {{
                              (team.fund_raised / team.fundraising_target) * 100
                                | number : "1.0-0"
                            }}% raised of ৳{{
                              team.fundraising_target | number
                            }}
                            Goal
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  }@else {
                  <div class="font_24">Theres no team to show</div>
                  }
                </div>
                <div class="buttons_teams">
                  <div class="white-button-md fw-bold">See All</div>
                  <div class="white-button-md fw-bold">Add Fundraiser</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    } @if(tab === 2) {
    <app-campaign-gallery
      [galleryId]="galleryId"
      (galleryItems)="getGalleryItems($event)"
    ></app-campaign-gallery>
    } @if(tab === 3) {
    <app-campaign-details
      [showDonationSummary]="false"
      [showMoreDonorExperienceButton]="false"
      [superAdminDashboard]="superAdminDashboard"
    ></app-campaign-details>
    }
  </section>
</div>

<dx-popup
  [(visible)]="showAnnouncement"
  [minWidth]="703"
  [minHeight]="634"
  [maxWidth]="703"
  [maxHeight]="634"
  [showTitle]="true"
  title="Announcements"
  [showCloseButton]="true"
  [dragEnabled]="false"
  [resizeEnabled]="false"
  position="center"
  [enableBodyScroll]="false"
  [hideOnOutsideClick]="false"
>
  <div class="form_div">
    <div class="input_div">
      <label class="field_label requiredMark">Title</label>
      <dx-text-box
        class="input_field"
        [(ngModel)]="announcementModel.title"
        placeholder="Announcements title"
        [showClearButton]="true"
      >
        <dx-validator validationGroup="announcementValidationGrp">
          <dxi-validation-rule
            type="required"
            message="Campaign title is required"
          ></dxi-validation-rule>
        </dx-validator>
      </dx-text-box>
    </div>
    <div class="input_div">
      <label class="field_label requiredMark">Description</label>
      <dx-html-editor
        #htmlEditor
        height="314"
        [(value)]="announcementModel.body"
        style="background-color: white"
        [allowSoftLineBreak]="true"
        placeholder="Message body"
      >
        <dxo-toolbar [multiline]="true">
          <dxi-item name="undo"></dxi-item>
          <dxi-item name="redo"></dxi-item>
          <dxi-item name="separator"></dxi-item>
          <dxi-item
            name="size"
            [acceptedValues]="[
              '8px',
              '10px',
              '12px',
              '14px',
              '16px',
              '18px',
              '20px',
              '22px',
              '24px',
              '26px',
              '28px',
              '30px',
              '32px',
              '34px',
              '36px',
              '38px'
            ]"
            [options]="{ inputAttr: { 'aria-label': 'Font size' } }"
          ></dxi-item>
          <dxi-item
            name="font"
            [acceptedValues]="[
              'Arial',
              'Courier New',
              'Georgia',
              'Impact',
              'Inter',
              'Lucida Console',
              'Tahoma',
              'Times New Roman',
              'Verdana'
            ]"
            [options]="{ inputAttr: { 'aria-label': 'Font family' } }"
          ></dxi-item>
          <dxi-item name="separator"></dxi-item>
          <dxi-item name="bold"></dxi-item>
          <dxi-item name="italic"></dxi-item>
          <dxi-item name="strike"></dxi-item>
          <dxi-item name="underline"></dxi-item>
          <dxi-item name="separator"></dxi-item>
          <dxi-item name="alignLeft"></dxi-item>
          <dxi-item name="alignCenter"></dxi-item>
          <dxi-item name="alignRight"></dxi-item>
          <dxi-item name="alignJustify"></dxi-item>
          <dxi-item name="separator"></dxi-item>
          <dxi-item name="orderedList"></dxi-item>
          <dxi-item name="bulletList"></dxi-item>
          <dxi-item name="separator"></dxi-item>
          <dxi-item
            name="header"
            [acceptedValues]="[false, 1, 2, 3, 4, 5]"
            [options]="{ inputAttr: { 'aria-label': 'Header' } }"
          ></dxi-item>
          <dxi-item name="separator"></dxi-item>
          <dxi-item name="color"></dxi-item>
          <dxi-item name="background"></dxi-item>
          <dxi-item name="separator"></dxi-item>
          <dxi-item name="link"></dxi-item>
          <dxi-item name="image"></dxi-item>
          <dxi-item name="separator"></dxi-item>
          <dxi-item name="clear"></dxi-item>
          <dxi-item name="blockquote"></dxi-item>
        </dxo-toolbar>
        <dxo-media-resizing [enabled]="true"> </dxo-media-resizing>
        <dxo-image-upload [tabs]="['file', 'url']" fileUploadMode="base64">
        </dxo-image-upload>
        <dx-validator validationGroup="announcementValidationGrp">
          <dxi-validation-rule
            type="required"
            message="Message body is required"
          >
          </dxi-validation-rule>
        </dx-validator>
      </dx-html-editor>
    </div>
  </div>
  <div class="button_group" style="margin-bottom: 0px">
    <!-- style="width: 116px" -->
    <dx-button
      class="blue-button-md announcement_btn"
      [text]="announcementUpdateMode ? 'Update' : 'Send'"
      (click)="submitAnnouncement()"
    >
    </dx-button>
    <dx-button
      class="blue-button-md announcement_btn"
      [text]="'Delete'"
      *ngIf="announcementUpdateMode"
      (click)="deleteFunctionality()"
    >
    </dx-button>
  </div>
</dx-popup>

<dx-popup
  class="add_announcement"
  [(visible)]="addToDonorExperience"
  [minWidth]="703"
  height="auto"
  [maxWidth]="703"
  [maxHeight]="634"
  [showTitle]="true"
  [showCloseButton]="true"
  [dragEnabled]="false"
  [resizeEnabled]="false"
  position="center"
  [enableBodyScroll]="false"
  [hideOnOutsideClick]="false"
>
  <div class="d-flex w-100 flex-column">
    <div class="d-flex justify-content-center w-100 mb-3">
      <span class="font_24">Create Donor Experience</span>
    </div>

    <div class="d-flex gap-3">
      <img class="prof_pic" src="/assets/images/icon/profile_icon_gray.png" />

      <div class="d-flex flex-column">
        <div class="header_team_">Pritam Hossain</div>
        <div class="mb-2">
          <span class="font_14 fw-bold">12,132,921 </span>
          <span class="font_14">about 30 mins ago</span>
        </div>

        <div class="font_18 mb-4">
          The absolute devastation that you and your community are enduring is
          unimaginable. Sending you love and strength from Cleveland Ohio.
        </div>

        <div class="input_div mb-3">
          <label class="field_label">Add Campaign Update</label>
          <dx-text-box
            class="input_field"
            placeholder="Write here..."
            [showClearButton]="true"
          >
            <dx-validator validationGroup="validationGrp"> </dx-validator>
          </dx-text-box>
        </div>
        <div class="form-group mb-3">
          <div class="input_div">
            <div class="file-upload-container">
              <label class="w-100">
                <dx-text-box
                  class="uploader_input_field w-100"
                  placeholder="Add Photo/Videos"
                  [readOnly]="true"
                  [showClearButton]="false"
                  [ngModel]="
                    donorExperienceImage?.name ||
                    donorExperienceImage?.originalname
                  "
                >
                  <dx-validator validationGroup="validationGrp"> </dx-validator>
                </dx-text-box>
              </label>
              <div
                *ngIf="true"
                class="blue-button-md"
                type="button"
                (click)="openGallery = true"
              >
                Browse File
              </div>
              <img
                class="cursor"
                title="Remove"
                *ngIf="false"
                src="./assets/images/icon/cross-icon.svg"
              />
            </div>
          </div>
        </div>
        <div class="add_to_donor font_24" (click)="addCommentToWall()">
          Add to Donor Experience
        </div>
      </div>
    </div>
  </div>
</dx-popup>

<dx-popup
  class="add_announcement"
  style="background-color: #f1f2f5 !important; z-index: 900 !important"
  [(visible)]="openGallery"
  [minWidth]="'80%'"
  height="auto"
  [maxWidth]="'80%'"
  [maxHeight]="'80%'"
  [showTitle]="true"
  title="My Gallery"
  [showCloseButton]="true"
  [dragEnabled]="false"
  [resizeEnabled]="false"
  position="center"
  [enableBodyScroll]="false"
  [hideOnOutsideClick]="false"
>
  <div class="d-flex w-100 flex-column">
    <div
      class="d-flex justify-content-between align-items-center w-100 mb-3 px-3"
    >
      @if(galleryImages?.length > 0){
      <dx-text-box class="gallery_search">
        <dxi-button
          name="searchIcon"
          location="before"
          [options]="searchIcon"
        ></dxi-button
      ></dx-text-box>
      } @else {
      <div class="font_24">No images in the gallery.</div>
      }
      <button
        class="blue-button-md"
        style="padding-bottom: 3px"
        (click)="triggerFileInput('imageInput')"
      >
        <img src="./assets/images/icon/save_icon_white.svg" />
        Insert from Device
      </button>
      <input
        #imageInput
        type="file"
        class="d-none"
        id="imageInput"
        (change)="onFileChange($event)"
        aria-hidden="true"
        multiple="true"
        accept=".jpg, .jpeg, .png"
      />
    </div>

    <div class="d-flex row">
      <div
        class="col-sm-12 col-lg-6 col-xl-4 mb-4"
        *ngFor="let image of galleryImages; let g_i = index"
      >
        <div class="select_image">
          <div
            class="image"
            [ngStyle]="{
              'background-image': 'url(' + image?.url + ')'
            }"
          >
            <div
              *ngIf="!selectedImage[g_i]"
              class="select cursor"
              (click)="selectImage(image, g_i)"
            ></div>
            <img
              *ngIf="selectedImage[g_i]"
              class="select border-none cursor"
              src="./assets/images/icon/select_icon_yellow.svg"
              (click)="selectImage(image, g_i)"
            />
          </div>
          <div class="d-flex justify-content-between">
            <div>
              <div class="font_22">{{ image?.originalname }}</div>
              <div class="png">
                <span>{{ image?.resource_type }}</span>
                <!-- - <span>1500x800</span> -->
              </div>
            </div>
            <div class="image_video">{{ extractedText(image?.mimetype) }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</dx-popup>
